@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* Color Pallete */
  --primary: #c6fe1e;
  --complementary: #e74c3c;
  --primary-light: #d4fd5ac1;
  --complementary-light: #eb4d4b;
  --background-dark: #10100e;
  --background-light: #202020;
  --primary-grey: #f5f5f599;
  --primary-white: #fff;
  --gradient1: #b6f6cf;
  --gradient2: #ecf6be;
  --text-heading: #10100e;
  --text-paragraph: #696b70;
  --text-button: #10100e;
  --secondary-npay: #071952;
  --primary-npay: #36aac4;
  --primary-npay-blur: rgba(54, 170, 196, 0.3);
  --primary-npay-blury: rgba(54, 170, 196, 0.7);

  /* Font Styles */
  --primary-font-family: sans-serif;

  /* Font Sizes */
  --h1: 4.5rem;
  --h3: 3.35rem;
  --h2: 2.65rem;
  --h3: 2rem;
  --h4: 1.5rem;
  --h5: 0.9rem;
  --paragraph: 1rem;
  --paragraph-small: 1.2rem;
  --button: 0.9rem;
  --link: 0.95rem;

  /*gradient*/
  --primary-gradient: linear-gradient(
    90deg,
    rgba(182, 246, 207, 1) 0%,
    rgba(236, 246, 190, 1) 100%
  );
  --secondary-gradient: linear-gradient(
    var(--background-dark),
    var(--background-light)
  );
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 650px) {
  :root {
    /* Font Sizes */
    --h1: 3.25rem;
    --h3: 2.75rem;
    --h2: 2.2rem;
    --h3: 1.65rem;
    --h4: 1.25rem;
    --h5: 0.85rem;
    --paragraph: 0.8rem;
    --paragraph-small: 0.75rem;
    --button: 0.8rem;
    --link: 0.75rem;
  }
}
